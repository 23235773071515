import {
  objOfGrayBarMax_Cost,
  objOfGrayBarMin_Cost,
} from "../../../../utils/constants";

export const MultiSlideInput = ({ filter, setFilter, filterOpt }) => {
  const handleFilterChange = (e: any, type: string) => {
    const value = e.target.value;
    const objValue = { ...filter.level };
    objValue[type] = value;

    if (type === "min") {
      if (+value >= +filter.level.max) {
        return setFilter(prevState => ({
          ...prevState,
          level: { ...filter.level, min: `${+filter.level.max - 1}` },
        }));
      }
    } else if (type === "max") {
      if (+value <= +filter.level.min) {
        return setFilter(prevState => ({
          ...prevState,
          level: { ...filter.level, max: `${+filter.level.min + 1}` },
        }));
      }
    }

    setFilter(prevState => ({
      ...prevState,
      level: objValue,
    }));
  };

  return (
    <div className="flex flex-col gap-8 md:flex-row">
      <div className="-mr-6  flex flex-col items-start justify-center">
        <label htmlFor={`Level`} className=" text-center text-lg text-white">
          Level:
        </label>
        <div className={`rangeslider`}>
          <span className="flex w-[20px] justify-center">
            {filter.level.min}
          </span>
          <div className="container-ranges">
            <input
              type="range"
              min={1}
              max={5}
              onChange={e => handleFilterChange(e, "min")}
              name={`Level`}
              value={filter.level.min}
            />
            <div
              className={`absolute left-[25px] top-[11px] z-[999] h-[2px] max-w-[54%] bg-gray-500`}
            ></div>
            <div
              className={`absolute right-[25px] top-[11px] z-[999] h-[2px] max-w-[54%] bg-gray-500`}
            ></div>
            <input
              type="range"
              min={1}
              max={5}
              onChange={e => handleFilterChange(e, "max")}
              name={`Level`}
              value={filter.level.max}
            />
            <div
              style={{ width: `${objOfGrayBarMin_Cost[filter.level.min]}` }}
              className={`absolute left-[25px] top-[11px] z-[999] h-[2px] max-w-[54%] bg-gray-500`}
            ></div>
            <div
              style={{ width: `${objOfGrayBarMax_Cost[filter.level.max]}` }}
              className={`absolute right-[25px] top-[11px] z-[999] h-[2px] max-w-[54%] bg-gray-500`}
            ></div>
          </div>
          <span className="w-[20px]">{filter.level.max}</span>
        </div>
      </div>
      {filterOpt[1].inactives !== null && (
        <div className="flex">
          <input
            value={filter.level.inactives}
            type="checkbox"
            className="!h-[20px] w-[35px] before:!h-[20px]
          before:!w-[15px] after:!h-[10px]
          after:!w-[15px] "
            onClick={evt =>
              setFilter(prevState => {
                const newState = JSON.parse(JSON.stringify(prevState));
                newState.level.inactives = !filter.level.inactives;
                return newState;
              })
            }
          />
          <small className="w-[120px] whitespace-nowrap">
            {!filter.level.inactives ? "Show inactives" : "Hide Inactives"}{" "}
          </small>
        </div>
      )}
    </div>
  );
};
