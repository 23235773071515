import { IFormatedAsset } from "../../../../@types";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useFetch } from "../../../../hooks/useFetch";
import useWax, { IMutateOptions } from "../../../../hooks/useWax";
import { SWRCacheKeyGetters, atomicFetcher } from "../../../../services/swr";
import {
  checkBalanceForBulkRepair,
  costToRepair,
  handleMessages,
} from "../../../../utils/errorHandlers";

export const BattleAcessoriesBulkActionsButtons = ({ allToRepair, type }) => {
  const { zombieTokenBalance, userInventory } = useFetch();

  const { currentUser } = useCurrentUser();
  const { contractRepairNFT } = useWax();

  const bulkRepairBoudaryMessages = checkBalanceForBulkRepair(
    allToRepair,
    zombieTokenBalance ?? [],
    type,
  );

  const mutateOptions: IMutateOptions[] = [
    {
      key: SWRCacheKeyGetters.getUserInventory(currentUser),
      currentData: userInventory,
      fetcher: atomicFetcher,
    },
  ];

  return (
    <div className="flex items-end justify-center">
      <div className="tooltip">
        <span className="button !left-[0px] !w-[250px]">
          This action will repair {type} with 0 durability only.
          <br /> Repair costs X ZBC per amulet.
          {allToRepair.length > 20 && (
            <>
              <br /> Up to 20 {type} can be repaired at once. Only 20 assets at
              time
            </>
          )}
          <br />
          This action will cost {allToRepair.length * costToRepair}.000 ZBC{" "}
        </span>
        <button
          className={
            (bulkRepairBoudaryMessages.length > 0 ? "grayscale " : "") +
            "gamebtn w-[250px]"
          }
          onClick={
            bulkRepairBoudaryMessages.length > 0
              ? () => handleMessages(bulkRepairBoudaryMessages)
              : () =>
                  contractRepairNFT(
                    allToRepair.map((nft: IFormatedAsset) => nft.assetId),
                    mutateOptions,
                  )
          }
        >
          Repair Broken {type}{" "}
          {allToRepair.slice(0, 20).length === allToRepair.length ||
          allToRepair.length < 20
            ? allToRepair.length
            : `${allToRepair.slice(0, 20).length} / ${allToRepair.length}`}
        </button>
      </div>
    </div>
  );
};
