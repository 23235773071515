import {
  checkBalanceForRepair,
  checkToolBalanceForUpgrade,
  handleMessages,
} from "../../../../../utils/errorHandlers";

export const BattleAcessoriesButtons = ({
  item,
  handleAcessoriesButton,
  userBalance,
}) => {
  const RepairBoudaryMessages = checkBalanceForRepair(
    item,
    userBalance?.zombieTokenBalance,
  );

  const UpgradeBoudaryMessages = checkToolBalanceForUpgrade(
    item,
    userBalance.tools.upgradeTools,
  );

  return (
    <div className="mb-2 flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <button
          className={
            (RepairBoudaryMessages.length > 0 ? " !grayscale " : "") +
            "gamebtn relative cursor-pointer !py-[4px] !text-[14px] transition-all"
          }
          onClick={
            RepairBoudaryMessages.length > 0
              ? () => handleMessages(RepairBoudaryMessages)
              : () => handleAcessoriesButton("repair")
          }
        >
          <div className="content text-center">Repair</div>
        </button>
        <button
          className={
            (UpgradeBoudaryMessages.length > 0 ? " !grayscale " : "") +
            "gamebtn relative cursor-pointer !py-[4px] !text-[14px] transition-all"
          }
          onClick={
            UpgradeBoudaryMessages.length > 0
              ? () => handleMessages(UpgradeBoudaryMessages)
              : () => handleAcessoriesButton("levelup-tool")
          }
        >
          <div className="content text-center">Level Up</div>
        </button>
      </div>
    </div>
  );
};
