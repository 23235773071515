import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";

import { BalanceBoard } from "../components/BalanceBoard";
import { useAuth } from "../hooks/useAuth";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useGame } from "../hooks/useGame";
import { publicRoutes } from "../utils/constants";
import { Footer } from "./partials/Footer";
import { Header } from "./partials/Header";
import LoginRequest from "./partials/LoginRequest";

interface Props {
  children: ReactNode;
}

export const MainTemplate = ({ children }: Props) => {
  const [offset, setOffset] = useState(0);
  const { currentUser } = useCurrentUser();
  const router = useRouter();

  const { activeUserData } = useAuth();
  const { stage } = useGame();

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={`bg-battle o min-h-screen overflow-hidden`}>
      {(stage === "selection" || stage === "queue") && <Header />}
      <div className=" flex min-h-[calc(100vh_-_5em)] flex-col">
        <div className="relative mb-[60px] mt-[5px] md:mb-[30px]">
          {currentUser ? <div>{children}</div> : <LoginRequest />}
        </div>
        {!currentUser && <Footer />}
      </div>
    </div>
  );
};
