import toast from "react-hot-toast";

import {
  checkBalanceForActivation,
  checkBalanceForTrain,
  checkLevelUpReqs,
  handleMessages,
} from "../../../../../utils/errorHandlers";

export const PetsButtons = ({
  item,
  activate,
  handlePetsButton,
  userBalance,
  levelCap,
}) => {
  const levelUpBoudaryMessages = checkLevelUpReqs(
    item?.data,
    levelCap,
    userBalance?.potions?.levelUpPotions,
  );
  const activationBoudaryMessages = checkBalanceForActivation(
    item,
    userBalance.zombieTokenBalance,
  );
  const TrainBoudaryMessages = checkBalanceForTrain(
    item?.data,
    userBalance?.potions?.trainPotions,
    levelCap,
  );

  const handleOnClick = () => {
    if (!activate) {
      if (activationBoudaryMessages.length > 0) {
        return () => handleMessages(activationBoudaryMessages);
      }
      return () => handlePetsButton("activation");
    }

    if (levelUpBoudaryMessages.length > 0) {
      return () => handleMessages(levelUpBoudaryMessages);
    }
    return () => handlePetsButton("levelup");
  };

  return (
    <div className=" flex flex-col gap-1">
      <div className="tooltip">
        <span className="button">
          {activate
            ? "To level up your pet, you need to have a level up potion."
            : "This action will cost 100.000 ZBC. Pets released prior to PVP must be activated to be used here."}
        </span>

        <button
          className={
            ((!activate
              ? activationBoudaryMessages.length
              : levelUpBoudaryMessages.length) > 0
              ? " !grayscale "
              : "") +
            " gamebtn relative !h-[30px] !w-[135px] cursor-pointer !py-[4px] !text-xs transition-all disabled:!grayscale"
          }
          onClick={handleOnClick()}
        >
          <div className="content text-center">
            {!activate ? "Activate" : "Level Up"}
          </div>
        </button>
      </div>
      {activate && (
        <div className="inventory-div">
          <button
            className={
              (TrainBoudaryMessages.length > 0 ? " !grayscale " : "") +
              "gamebtn relative !h-[30px] !w-[135px] cursor-pointer !py-[4px] !text-xs transition-all "
            }
            onClick={
              TrainBoudaryMessages.length > 0
                ? () => handleMessages(TrainBoudaryMessages)
                : () => handlePetsButton("train")
            }
          >
            <div className="content text-center">Train</div>
          </button>
        </div>
      )}
    </div>
  );
};
