import Image from "next/image";
import { useEffect } from "react";

import { WaxLoginbutton } from "../../../components/UI/Buttons/LoginButtons";
import { useAuth } from "../../../hooks/useAuth";

interface Props {
  waxRouter?: boolean;
}

const LoginRequest = ({ waxRouter }: Props) => {
  const { signIn, activeUserData } = useAuth();

  useEffect(() => {
    if (activeUserData?.actor.toString()) {
      location.reload();
    }
  }, [activeUserData]);

  return (
    <div
      className={`mt-4 flex flex-col items-center justify-center gap-6 text-primary `}
    >
      <div className="bg-container flex w-[350px] flex-col items-center justify-center gap-2 rounded-2xl bg-[#00000055] p-4 shadow-2xl drop-shadow-xl md:w-auto xl:gap-8">
        <div className={`flex flex-col items-center gap-2 text-center`}>
          <div className="overflow-hidden rounded-full">
            <Image
              src={"/image/logos/zombiecoin-logo.png"}
              alt={"Zombiecoin Logo"}
              width={64}
              height={64}
            />
          </div>
          <span className={`text-2xl font-bold md:text-3xl`}>
            Welcome to Zombiecoin Arena.
          </span>
          <span className={`text-lg font-medium md:text-xl`}>
            Choose your wallet provider below to log in.
          </span>
        </div>
        <div className={`flex w-full flex-col items-center `}>
          <div className={`flex w-full flex-col items-center justify-center`}>
            <div className="flex max-w-sm flex-col gap-4 px-6 sm:px-0">
              <WaxLoginbutton click={signIn} />
            </div>
          </div>
        </div>
        <div className={`flex flex-col items-center gap-1`}>
          <span>
            Need help?{" "}
            <span
              className={`cursor-pointer underline hover:text-yellow-500`}
              onClick={() => open("https://discord.com/invite/yq44jGwggF")}
            >
              Join our Discord.
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginRequest;
