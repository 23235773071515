import { useState } from "react";
import { FaGem } from "react-icons/fa";
import { GiCannon, GiTurtle } from "react-icons/gi";

import { useFetch } from "../../../hooks/useFetch";
import { cleanAndCapitalize } from "../../../utils";
import { RenderCardsInventory } from "../../UI/RenderCardsInventory";

export const InventoryContent = () => {
  const { userInventory, isLoadingUserInventory } = useFetch();
  const [tab, setTab] = useState("all");

  if (isLoadingUserInventory) {
    return (
      <div className="min-w-screen flex min-h-screen items-center justify-center">
        <div className="loading-circle"></div>
      </div>
    );
  }

  return (
    <div className="quests-div m-12 flex flex-col gap-2">
      <nav className="relative ml-12 flex flex-wrap gap-4 text-center md:absolute md:right-8 md:top-[100px]">
        {["all", "pets", "cannons", "amulets"].map((navItem, _) => {
          return (
            <div
              key={_}
              onClick={() => setTab(navItem)}
              className={` ${
                navItem === tab ? " text-white " : "!text-[#ffffffa9]"
              } group cursor-pointer hover:!text-white`}
            >
              <div
                className={`  ${
                  navItem === tab ? " !border-white " : "!border-[#ffffffa9]"
                } border border-solid border-white group-hover:!border-white `}
              >
                {{
                  pets: <GiTurtle className="h-[64px] w-[64px]" />,
                  amulets: <FaGem className="h-[64px] w-[64px]" />,
                  cannons: <GiCannon className="h-[64px] w-[64px]" />,
                  all: (
                    <div className="relative flex h-[64px] w-[64px] flex-wrap justify-center">
                      <span className="">
                        <GiTurtle className=" left-0 h-[32px] w-[32px]" />
                      </span>
                      <span>
                        <FaGem className=" right-0 h-[32px] w-[32px]" />{" "}
                      </span>
                      <span>
                        <GiCannon className=" h-[32px] w-[32px]" />
                      </span>
                    </div>
                  ),
                }?.[navItem] || <></>}
              </div>
              <div>{cleanAndCapitalize(navItem)}</div>
            </div>
          );
        })}
      </nav>
      {(tab === "pets" || tab === "all") && (
        <RenderCardsInventory type={`Pets`} userInventory={userInventory} />
      )}
      {(tab === "cannons" || tab === "all") && (
        <RenderCardsInventory type={`Cannons`} userInventory={userInventory} />
      )}
      {(tab === "amulets" || tab === "all") && (
        <RenderCardsInventory type={`Amulets`} userInventory={userInventory} />
      )}
    </div>
  );
};
