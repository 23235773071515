import React from "react";

import Loader from "../Loader";

interface ClanData {
  clan: {
    clanowner: string;
    clanname: string;
    claninfo: string;
    memberlimit: number;
    memberlist: string[];
  };
  configset: string;
  clanPlayers: any[];
  points: number;
}

interface ClansRankingProps {
  ranking: ClanData[] | null;
}

const tableStructureStyle =
  "!w-[550px] min-w-[100%] whitespace-nowrap md:!w-[950px] md:justify-around md:gap-0 justify-start gap-8 flex justify-start border-b-2 md:w-auto";

const tableCellWidth = {
  lg: "min-w-[150px] max-w-[150px] whitespace-nowrap",
  md: "min-w-[100px] max-w-[100px]  whitespace-nowrap",
  sm: "min-w-[50px] max-w-[50px]  whitespace-nowrap",
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const ClansRanking = ({ ranking }: ClansRankingProps) => {
  return (
    <div className="overflow-x-scroll xl:overflow-x-visible">
      <div className={`my-5 flex ${tableStructureStyle}`}>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Position
        </span>
        <span
          className={`font-impact ${tableCellWidth.lg} font-medium uppercase text-white md:text-xl`}
        >
          Clan Name
        </span>
        <span
          className={`font-impact ${tableCellWidth.md} font-medium uppercase text-white md:text-xl`}
        >
          Clan Owner
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Points
        </span>
        <span
          className={`font-impact ${tableCellWidth.md} font-medium uppercase text-white md:text-xl`}
        >
          Members
        </span>
      </div>
      {!ranking && <Loader />}
      {ranking && ranking.length > 0 ? (
        ranking.map((data, index) => (
          <div
            key={data.clan.clanname}
            className={`mb-5 mt-5 flex ${tableStructureStyle}`}
          >
            <span
              className={`font-impact ${
                tableCellWidth.sm
              } font-medium uppercase md:text-lg ${
                index < 20 ? "text-yellow-300" : "text-white"
              }`}
            >
              #{index + 1}
            </span>
            <span
              className={`font-impact ${tableCellWidth.lg} cursor-copy font-medium lowercase text-white md:text-lg`}
              onClick={() => copyToClipboard(data.clan.clanname)}
            >
              {data.clan.clanname}
            </span>
            <span
              className={`font-impact ${tableCellWidth.md} cursor-copy font-medium lowercase text-white md:text-lg`}
              onClick={() => copyToClipboard(data.clan.clanowner)}
            >
              {data.clan.clanowner}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} group relative font-medium uppercase text-white md:text-lg`}
            >
              {data.clanPlayers.length > 0 && (
                <span className="absolute bottom-6 -left-6 z-[999] hidden max-h-[30vh] min-h-fit min-w-fit flex-col flex-wrap overflow-y-auto rounded-lg bg-basket_blue-900 p-4 text-center lg:hover:flex lg:group-hover:flex">
                  {data.clanPlayers.map((member, index) => (
                    <span key={index} className="truncate">
                      {member.player}: {member.points}
                      {index < data.clanPlayers.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              )}
              {data.points}
            </span>
            <span
              className={`font-impact ${tableCellWidth.md} group relative font-medium uppercase text-white md:text-lg`}
            >
              <span className="absolute -left-6 bottom-6  z-[999] hidden max-h-[30vh] min-h-fit min-w-fit flex-col flex-nowrap overflow-y-auto rounded-lg bg-basket_blue-900 p-4 text-center lg:hover:flex lg:group-hover:flex">
                {data.clan.memberlist.length > 0 &&
                  data.clan.memberlist.map((member, index) => (
                    <span key={index} className="truncate">
                      {member}
                      {index < data.clan.memberlist.length - 1 && ", "}
                    </span>
                  ))}
              </span>
              {data.clan.memberlist.length}/{data.clan.memberlimit}
            </span>
          </div>
        ))
      ) : (
        <div className="flex h-[300px] items-center justify-center">
          <span className="text-2xl text-[#eee]">No results found.</span>
        </div>
      )}
    </div>
  );
};

export default ClansRanking;
