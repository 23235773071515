import { motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import { FaHandPointDown } from "react-icons/fa";

import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useFetch } from "../../../hooks/useFetch";
import { QuestDisplayItem } from "../../UI/Items/QuestDisplayItem";

export const Quests = () => {
  const { allQuests, isLoadingAllQuests } = useFetch();
  const { currentUser } = useCurrentUser();
  const [itemsMoreThenViewPort, setItemsMoreThenViewPort] = useState(false);

  const questsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHeight = () => {
      if (questsRef.current) {
        setItemsMoreThenViewPort(
          questsRef.current.scrollHeight > window.innerHeight,
        );
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);

  if (isLoadingAllQuests || !allQuests) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <div className="loading-circle">
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <section className="mb-10 mt-10 flex w-full flex-col items-center justify-center md:w-[100%] md:min-w-[405px] ">
      <article className={`flex flex-col items-center gap-2`}>
        <h4 className="font-impact relative w-full text-center text-4xl font-normal uppercase text-white">
          Quests{" "}
          {itemsMoreThenViewPort && (
            <span className="absolute -right-20 flex animate-bounce flex-col items-center justify-center">
              <span className="whitespace-nowrap text-xs">Scroll Down</span>
              <FaHandPointDown />
            </span>
          )}
        </h4>
        <div className="flex flex-col gap-4 md:flex-row">
          <h5 className="font-impact w-full whitespace-nowrap text-center text-xl  text-white">
            All Available Quests: {allQuests?.quests?.length || 0}
          </h5>
          <h5 className="font-impact w-full whitespace-nowrap text-center text-xl text-white">
            Active Quests:{" "}
            {(allQuests?.quests &&
              Array.isArray(allQuests.quests) &&
              allQuests.quests.filter(e =>
                e.participations?.find(
                  e => e?.player?.player?.player === currentUser,
                )?.lastClaimDate
                  ? new Date(
                      e.participations?.find(
                        e => e?.player?.player?.player === currentUser,
                      )?.lastClaimDate || new Date(0),
                    ).getTime() +
                      (e.isDaily
                        ? 24 * 60 * 60 * 1000
                        : new Date(
                            new Date(
                              e.participations?.find(
                                e => e?.player?.player?.player === currentUser,
                              )?.lastClaimDate || new Date(0),
                            ).setMonth(
                              new Date(
                                e.participations?.find(
                                  e =>
                                    e?.player?.player?.player === currentUser,
                                )?.lastClaimDate || new Date(0),
                              ).getMonth() + 1,
                            ),
                          ).getTime() -
                          new Date(
                            e.participations?.find(
                              e => e?.player?.player?.player === currentUser,
                            )?.lastClaimDate || new Date(0),
                          ).getTime()) <
                    Date.now()
                  : true,
              )?.length) ||
              0}
          </h5>
          <h5 className="font-impact w-full whitespace-nowrap text-center text-xl text-white">
            Completed Quest:{" "}
            {(allQuests?.quests &&
              Array.isArray(allQuests.quests) &&
              allQuests.quests.filter(e =>
                e.participations?.find(
                  e => e?.player?.player?.player === currentUser,
                )?.lastClaimDate
                  ? new Date(
                      e.participations?.find(
                        e => e?.player?.player?.player === currentUser,
                      )?.lastClaimDate || new Date(0),
                    ).getTime() +
                      (e.isDaily
                        ? 24 * 60 * 60 * 1000
                        : 30 * 24 * 60 * 60 * 1000) >=
                    Date.now()
                  : false,
              )?.length) ||
              0}
          </h5>
        </div>
      </article>

      <section
        ref={questsRef}
        className="flex flex-col items-center justify-center"
      >
        {allQuests.quests && Array.isArray(allQuests.quests) ? (
          allQuests.quests.map((e, _) => {
            return (
              <motion.div
                key={_}
                whileInView={{ y: [100, 0], opacity: [0, 1] }}
                initial={{ y: 100, opacity: 0 }}
              >
                <QuestDisplayItem key={_} quest={e} />
              </motion.div>
            );
          })
        ) : (
          <div className="flex h-[200px] items-center justify-center font-bold">
            Couldn&apos;t find any active quest now
          </div>
        )}
      </section>
    </section>
  );
};
