import { Dialog } from "@headlessui/react";
import { on } from "events";
import { motion } from "framer-motion";
import { SetStateAction, Dispatch, ReactElement } from "react";
import { AiOutlineClose } from "react-icons/ai";

interface FullModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  children?: ReactElement;
  isEndGame?: boolean;
}

export const FullModal = ({
  isOpen = false,
  setIsOpen,
  children,
  isEndGame = false,
}: FullModalProps) => {
  return (
    <Dialog open={isOpen} onClose={() => null}>
      <Dialog.Panel className="styled-scrollbar fixed inset-0 z-[200] min-h-full w-full overflow-x-hidden overflow-y-scroll bg-[#0f0f0f] bg-opacity-90">
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
          {!isEndGame && (
            <button
              onClick={() => setIsOpen(false)}
              className="absolute left-5 top-5 rounded-md border text-3xl"
            >
              <AiOutlineClose color="#fff" />
            </button>
          )}
          {children}
        </motion.div>
      </Dialog.Panel>
    </Dialog>
  );
};
