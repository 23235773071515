import { useFetch } from "../../../hooks/useFetch";
import { useFilter } from "../../../hooks/useFilters";
import { isActivated } from "../../../utils";
import {
  petsCardSchema,
  cannonsCardSchema,
  boostCardSchema,
  trainPotionTemplateId,
  levelUpPotionTemplateId,
  levelUpToolTemplateId,
} from "../../../utils/constants";
import { BattleAcessoriesBulkActionsButtons } from "../Buttons/BattleAcessoriesBulkActionsButtons";
import { PetsBulkActionsButtons } from "../Buttons/PetsBulkActionsButtons";
import { InventoryItem } from "../Items/InventoryItem";

export const RenderCardsInventory = ({ type, userInventory }) => {
  const { applyFilter, renderFilters } = useFilter(type);
  const { zombieTokenBalance, zombieTemplates } = useFetch();

  const trainPotionTemplate = zombieTemplates?.find(
    nft => nft.template_id === trainPotionTemplateId,
  );

  const levelUpPotionTemplate = zombieTemplates?.find(
    nft => nft.template_id === levelUpPotionTemplateId,
  );

  const trainPotions = userInventory?.filter(
    nft => +nft.templateId === +trainPotionTemplateId,
  );
  const levelUpPotions = userInventory?.filter(
    nft => +nft.templateId === +levelUpPotionTemplateId,
  );

  const upgradeTools = userInventory?.filter(
    nft => +nft.templateId === +levelUpToolTemplateId,
  );

  const filterType = {
    Pets: petsCardSchema,
    Cannons: cannonsCardSchema,
    Amulets: boostCardSchema,
  };

  const allAssets =
    userInventory?.filter(nft => nft.schema === filterType[type]) || [];

  const showingAssets = applyFilter(
    userInventory.filter(nft => nft.schema === filterType[type]),
  );

  const allInactive = applyFilter(
    userInventory.filter(
      nft => nft.schema === filterType[type] && !isActivated(nft, nft.schema),
    ),
  );
  const allToRepair = applyFilter(
    userInventory.filter(
      nft =>
        nft.schema === filterType[type] &&
        nft?.attributes?.durability &&
        +nft?.attributes?.durability === 0,
    ),
  );

  return (
    <>
      <div className="mt-12 flex flex-col gap-8">
        <div className="mb-4 flex flex-col gap-2 lg:flex-row">
          <h3>
            <b>{type}</b>{" "}
          </h3>

          <small className="flex items-center">
            (
            {showingAssets?.length === allAssets?.length
              ? showingAssets?.length
              : `${showingAssets?.length} / ${allAssets?.length}`}
            )
          </small>
        </div>
        <div className="flex flex-col items-start justify-start gap-4 md:flex-row md:items-end">
          <div className="mr-4">{renderFilters()}</div>
          {type === "Pets" && (
            <PetsBulkActionsButtons allInactive={allInactive} />
          )}
          {(type === "Cannons" || type === "Amulets") && (
            <BattleAcessoriesBulkActionsButtons
              allToRepair={allToRepair}
              type={type}
            />
          )}
        </div>

        <div className="flex flex-wrap gap-2">
          {applyFilter(
            userInventory.filter(nft => nft.schema === filterType[type]),
          ).map((item, _) => (
            <InventoryItem
              key={_}
              item={item}
              type={filterType[type]}
              userBalances={{
                zombieTokenBalance,
                potions: { trainPotions, levelUpPotions },
                templates: { trainPotionTemplate, levelUpPotionTemplate },
                tools: { upgradeTools },
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};
