import Image from "next/image";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";

interface LoginModalProps {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  click: any;
}

export const WaxLoginbutton = ({ click, setIsOpen }: LoginModalProps) => {
  return (
    <div
      className={`flex h-[54px] w-[235px] cursor-pointer items-center`}
      onClick={() => {
        click();
        if (setIsOpen) setIsOpen(false);
      }}
    >
      <div className={`h-full rounded-l-xl bg-[#92480c] px-3 py-3`}>
        <div className={`relative h-[30px] w-[30px]`}>
          <Image
            src={"/image/icons/cloudwallet.png"}
            objectFit={"contain"}
            layout={"fill"}
            alt={"Wax Cloud Wallet"}
          />
        </div>
      </div>
      <div
        className={`flex h-full w-full items-center justify-center rounded-r-xl bg-[#F89122]`}
      >
        <span className={`font-roboto font-semibold text-white`}>
          WAX Wallet
        </span>
      </div>
    </div>
  );
};
