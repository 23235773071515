import Cookies from "js-cookie";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { IFormatedAsset } from "../@types";
import { IGameStages, ISelectedCards } from "../@types/game";
import { ISocketMatchFound, ISocketQueueDetails } from "../@types/socket";

export const GameActions = {
  start_match: "Match_start",
  attack: "Attack",
  cannon: "Cannon",
  break: "Break",
  defend: "Defend",
};

interface ISoundProvider {
  children: ReactNode;
}

interface ISoundContextProps {
  gameSound: (props: { typeAction: string; petName?: string }) => void;
  toggleSoundPreference: () => void;
  isDisabledSound: boolean;
}

export const SoundContext = createContext({} as ISoundContextProps);

export const SoundProvider = ({ children }: ISoundProvider) => {
  const initialValue = Cookies?.get("disabledSound") === "true";
  const [isDisabledSound, setIsDisabledSound] = useState<boolean>(
    initialValue || false,
  );

  const gameSound = ({
    typeAction,
    petName,
  }: {
    typeAction: string;
    petName?: string;
  }): void => {
    if (isDisabledSound) {
      const audioPaths = {
        Attack: petName ? `/audios/pets/${petName}.wav` : "",
        Cannon: "/audios/Cannon.wav",
        Break: "/audios/Break.mp3",
        Defend: "/audios/Defense.wav",
        Match_start: "/audios/match_start1.mp3",
      };

      const audioPath = audioPaths[typeAction];

      if (audioPath) {
        const audio = new Audio(audioPath);

        const isMatchStart = typeAction === GameActions.start_match;

        audio.volume = isMatchStart ? 0.2 : 0.06;

        audio.play();
      }
    }
  };

  useEffect(() => {
    const expiresIn365Days = 365;
    Cookies.set("disabledSound", isDisabledSound, {
      expires: expiresIn365Days,
    });
  }, [isDisabledSound]);

  const toggleSoundPreference = (): void => {
    setIsDisabledSound(prev => !prev);
  };

  return (
    <SoundContext.Provider
      value={{ gameSound, toggleSoundPreference, isDisabledSound }}
    >
      {children}
    </SoundContext.Provider>
  );
};
