import Image from "next/image";

export const GameGuideContent = () => {
  return (
    <section
      className={`flex min-h-screen w-screen flex-col items-center justify-start gap-8 pb-14 pt-16 text-white md:pt-28`}
    >
      <article id="titles" className={`flex flex-col items-center gap-2`}>
        <span className="text-3xl font-bold  ">Quick Guide</span>
      </article>

      <section
        className={`flex max-w-[350px] flex-col gap-5 rounded-lg bg-gray-800 p-8 shadow-2xl md:max-w-[650px] lg:max-w-[800px]`}
      >
        <div className={`flex flex-col gap-2`}>
          <ol className="flex list-decimal flex-col gap-3 pl-4 font-normal">
            <li>
              To start a match, select at least a <b>Pet</b>.
            </li>
            <li>
              If you have a Cannon and/or an Amulet, you can also select them.{" "}
            </li>
            <li>
              Click on <span>Find a Match</span>.
            </li>
            <li>Once an opponent is found, the match begins.</li>
            <li>
              You can select one action per turn, among the following:
              <ul className={`flex list-disc flex-col gap-2 pl-7 text-sm`}>
                <li>
                  <span>Attack</span>: this is a melee attempt of attack. The
                  greater the Pet{"'"}s strength, the greater the melee damage
                  dealt.
                </li>
                <li>
                  <span>Cannon</span>: This is a missile attack and consumes
                  mana. The greater the Cannon{"'"}s strength, the greater the
                  damage dealt.
                </li>
                <li>
                  <span>Defend</span>: this is an attempt to defend your
                  opponent{"'"}s attack. If you defend successfully, you also
                  get a chance to Counterattack in the next turn, which deals
                  greater damage.
                </li>
                <li>
                  <span>Break Defense</span>: this is an attempt to break your
                  opponent{"'"}s defense, making them unable to defend for 5
                  turns. It can only succeed if your opponent has used Defend.
                </li>
              </ul>
            </li>
            <li>
              You will play 3 rounds, each having 10 turns at most. The results
              for each turn are shown in the middle of the screen.
            </li>
            <li>
              The player who defeats the opponent (deals more damage than the
              opponent{"'"}s health points) or the player having the most HP
              left at the end of the 10th turn wins the round.
            </li>
            <li>The player who wins 2 rounds wins the battle.</li>
          </ol>
        </div>
        <div className={`flex flex-col gap-2`}>
          <span className="text-lg font-bold">Matchmaking System: </span>
          <span>
            Once a player clicks on <b>Find a Match</b>, the system tries to
            create a match between the player and an opponent chosen in this
            order:
          </span>
          <ol className={`flex list-decimal flex-col gap-3 pl-7`}>
            <li>Opponent player of the same level, up to 2 daily matches.</li>
            <li>
              Opponent player with 1 level difference, up to 2 daily matches.
            </li>
            <li>
              Automated player (<i>bot</i>) from the same level.
            </li>
          </ol>
        </div>
        <div className={`flex flex-col gap-2`}>
          <span className="text-lg font-bold">Ranking and Points: </span>
          <span>
            Players advance in ranking based on the number of points they win.
            Victory grants 3 points, and draw grants 2 points.
          </span>
          <span>The following rules apply:</span>
          <ol className={`flex list-decimal flex-col gap-3 pl-7`}>
            <li>
              Only the first 20 daily matches count for ranking purposes and
              therefore rewards.
            </li>
            <li>Each day starts at 0:00 UTC.</li>
            <li>
              If you sit idle for a number of turns, the match is interrupted in
              favor of your opponent.
            </li>
            <li>
              Abandoned or idled matches grant no points but count towards the
              daily limit.
            </li>
          </ol>
        </div>
        <div className={`flex flex-col gap-2`}>
          <span className="text-lg font-bold">Stats and Effects:</span>
          <span>
            Each Pet has these stats, followed by their effects in the match:
          </span>
          <ul>
            <li>Strength - increases melee attack damage.</li>
            <li>
              Intelligence - increases total mana, initial mana, mana
              regeneration and the odds of hitting with and avoiding missile
              attacks.
            </li>
            <li>
              Dexterity - increases melee critical chance and the odds of
              hitting with and avoiding melee attacks.
            </li>
            <li>
              Obedience - increases melee and missile damage, and the chance to
              attack/defend your opponent.
            </li>{" "}
          </ul>
          <span>
            The stats can be improved by leveling up your Pet or by using an
            Amulet.
          </span>
        </div>
        <div className={`flex flex-col gap-2`}>
          <span className="text-lg font-bold">Additional hints:</span>

          <ul className={`flex list-disc flex-col gap-2 pl-7`}>
            <li>
              You can hover on your cards to view your stats and odds, or on
              your opponent{"'"}s cards to see theirs.
            </li>
            <li>
              The actions can be triggered through the hotkeys:{" "}
              <span>A, S, D, F</span>.
            </li>
            <li>
              If you sit idle for a number of turns, the match is interrupted in
              favor of your opponent.
            </li>
          </ul>
          <p>&nbsp;</p>
        </div>
      </section>
    </section>
  );
};
