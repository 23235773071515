import { Menu } from "@headlessui/react";
import { ReactNode } from "react";

import { NavItem } from "../Items/NavItem";

interface Props {
  items: string[];
  children: ReactNode;
  handleOnClickNav: (string) => void;
}

export const NavDropdown = ({ children, items, handleOnClickNav }: Props) => {
  return (
    <Menu>
      <Menu.Button>{children}</Menu.Button>
      <Menu.Items className="absolute right-0 top-[30px] flex w-screen flex-col items-center justify-center gap-4 bg-[#000000bd] p-4 shadow-2xl">
        {items.map((item, _) => (
          <Menu.Item key={_}>
            <NavItem item={item} handleOnClickNav={handleOnClickNav} />
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};
