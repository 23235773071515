import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { FullModal } from "../../../components";
import Modal from "../../../components/Modal";
import { GameGuideContent } from "../../../components/ModalContent/GameGuide";
import { History } from "../../../components/ModalContent/History";
import { InventoryContent } from "../../../components/ModalContent/Inventory";
import { MarketContent } from "../../../components/ModalContent/Market";
import { Quests } from "../../../components/ModalContent/Quests";
import { Ranking } from "../../../components/ModalContent/Ranking";
import { NavItem } from "../../../components/UI/Items/NavItem";
import { NavDropdown } from "../../../components/UI/NavDropdown/Index";
import { useAuth } from "../../../hooks/useAuth";
import { useGame } from "../../../hooks/useGame";
import { useSocket } from "../../../hooks/useSocket";
import LoginRequest from "../LoginRequest";

export const Header = () => {
  useSocket();
  const { activeUserData, signOut } = useAuth();
  const [loginOpsOpen, setLoginOpsOpen] = useState(false);
  const [guideOpen, setGuideOpen] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [questsOpen, setQuestsOpen] = useState(false);
  const [marketIsOpen, setMarketIsOpen] = useState(false);
  const [rankingOpen, setRankingOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  const handleOnClickNav = (item: string) => {
    if (item === "Login") {
      if (activeUserData) {
        return signOut();
      }
      return setLoginOpsOpen(e => !e);
    }
    if (item === "Whitepaper") {
      return open("https://zombecoin.gitbook.io/zombiecoin-whitepaper/");
    }
    if (!activeUserData) {
      return toast("Please Log In To interact");
    }

    if (item === "Inventory") {
      return setInventoryOpen(e => !e);
    }

    if (item === "Marketplace") {
      return setMarketIsOpen(e => !e);
    }
    if (item === "Quick Guide") {
      return setGuideOpen(e => !e);
    }
    if (item === "Ranking") {
      return setRankingOpen(e => !e);
    }

    if (item === "Quests") {
      return setQuestsOpen(e => !e);
    }
    if (item === "History") {
      return setHistoryOpen(e => !e);
    }

    return toast("Comming Soon!");
  };

  const navItems = [
    "Inventory",
    "Quests",
    "History",
    "Ranking",
    "Marketplace",
    "Quick Guide",
    "Whitepaper",
    "Login",
  ];

  return (
    <>
      <header
        className={` h-[50px] w-screen items-center justify-between rounded-b-lg bg-[#000000a7] shadow-2xl md:mr-6 md:h-[75px]`}
      >
        <div className={`flex h-full items-center justify-between gap-5`}>
          <div className="relative flex items-center gap-10 ">
            <Link href={"/"} passHref>
              <div className="relative  ml-8 h-[64px] w-[64px] scale-50 cursor-pointer overflow-hidden rounded-full md:h-[48px] md:w-[48px] md:scale-100">
                <Image
                  src={"/image/logos/zombiecoin-logo.png"}
                  alt={"Zombie Coin Logo"}
                  layout={"fill"}
                  objectFit={"contain"}
                  className="overflow-hidden rounded-full"
                />
              </div>
            </Link>
          </div>
          <nav className=" flex items-center space-x-4 md:space-x-4">
            <div className="relative flex h-[45px] w-full items-center justify-end">
              <ol className="mr-4 hidden gap-4 lg:flex ">
                {navItems.map((item, _) => (
                  <NavItem
                    key={item + _}
                    item={item}
                    handleOnClickNav={handleOnClickNav}
                  />
                ))}
              </ol>
              <div className="p-auto absolute right-[3%] top-[3%] z-10 mt-4 flex h-[25px] w-[105px] flex-col items-center justify-center gap-4 lg:hidden">
                <NavDropdown
                  items={navItems}
                  handleOnClickNav={handleOnClickNav}
                >
                  <label id="togglerLable" htmlFor="toggleChecker">
                    <div className="checkboxtoggler">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <div className="line-3"></div>
                    </div>
                  </label>
                </NavDropdown>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <Modal isOpen={loginOpsOpen} setIsOpen={setLoginOpsOpen}>
        <LoginRequest />
      </Modal>
      <FullModal isOpen={guideOpen} setIsOpen={setGuideOpen}>
        <GameGuideContent />
      </FullModal>
      <FullModal isOpen={inventoryOpen} setIsOpen={setInventoryOpen}>
        <InventoryContent />
      </FullModal>
      <FullModal isOpen={marketIsOpen} setIsOpen={setMarketIsOpen}>
        <MarketContent />
      </FullModal>
      <FullModal isOpen={rankingOpen} setIsOpen={setRankingOpen}>
        <Ranking />
      </FullModal>
      <FullModal isOpen={questsOpen} setIsOpen={setQuestsOpen}>
        <Quests />
      </FullModal>
      <FullModal isOpen={historyOpen} setIsOpen={setHistoryOpen}>
        <History />
      </FullModal>
    </>
  );
};
