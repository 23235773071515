import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { IRound, ISocketMatchFound } from "../@types/socket";
import { useGame } from "../hooks/useGame";

interface IBattleProvider {
  children: ReactNode;
}

interface BattleContextProps {
  initialRoundData: ISocketMatchFound | null;
  setInitialRoundData: Dispatch<SetStateAction<ISocketMatchFound | null>>;
  activeTurn: any | null;
  setActiveTurn: Dispatch<SetStateAction<any | null>>;
  activeRoundData: IRound | undefined;
}

export const BattleContext = createContext({} as BattleContextProps);

export const BattleProvider = ({ children }: IBattleProvider) => {
  const { roundData } = useGame();
  const [initialRoundData, setInitialRoundData] =
    useState<ISocketMatchFound | null>(null);
  const [activeTurn, setActiveTurn] = useState<any | null>(null);

  useEffect(() => {
    setInitialRoundData(JSON.parse(JSON.stringify(roundData)));
  }, [roundData]);

  const activeRoundData = roundData?.data?.game?.rounds?.find(
    round => round?.isActive,
  );

  useEffect(() => {
    const turns =
      activeRoundData?.turns || initialRoundData?.data?.game?.rounds[0]?.turns;
    if (turns) {
      const activeTurn = turns?.find(turn => turn?.isActive);

      setActiveTurn(activeTurn || turns?.[0]);
    }
  }, [activeRoundData?.turns, initialRoundData?.data?.game?.rounds]);

  return (
    <BattleContext.Provider
      value={{
        initialRoundData,
        setInitialRoundData,
        activeTurn,
        setActiveTurn,
        activeRoundData,
      }}
    >
      {children}
    </BattleContext.Provider>
  );
};

const MockedBattle = {
  data: {
    game: {
      id: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
      date: "2024-02-01T18:51:20.857Z",
      winnerId: null,
      createdAt: "2024-02-01T18:51:20.857Z",
      updatedAt: "2024-02-01T18:51:20.857Z",
      turns: [
        {
          id: "6e456bb7-0e93-448f-90f9-01aaa806961d",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 1,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "2eb25e93-a705-49cd-9518-ff64ef325cb4",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 2,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "71ba9e30-d482-43a3-a4fe-c30fe37d49c2",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 3,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "9307a332-6226-442f-929e-d6567efff120",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 4,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "3b68efe4-9624-4bca-b9b3-d86d4f70aabb",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 5,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "f9196482-97c7-4895-9219-5e3aae3bb85a",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 6,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "69e75f4f-4524-4bcb-9ef0-7403541d888c",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 7,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "4165441f-9bff-468e-8a6c-3bc95a6a9453",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 8,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "ff505d86-80a1-493b-8853-77cb5dc6d3c8",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 9,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
        {
          id: "9aa61acc-d698-4817-a6f6-be2aac780b70",
          gameId: "2e8c3367-96fc-43db-ba96-e8fa7b7d5725",
          turn: 10,
          createdAt: "2024-02-01T18:51:20.857Z",
          updatedAt: "2024-02-01T18:51:20.857Z",
        },
      ],
    },
    players: {
      playerOne: {
        userID: "82895207-8bb2-472d-a388-b31c3d204263",
        player: "thetestguywx",
        deck: {
          id: "1138a7e6-11dc-4521-91c6-c7b9c2e95cb5",
          playerId: "f417dbe0-a961-4189-91b4-6d72af3cfc87",
          playerQueueId: "405b86b6-471b-4d13-9e36-23a614b6361f",
          cards: [
            {
              id: "9ce7f107-3e80-4bc4-bce1-7c1f37e8fb85",
              deckId: "1138a7e6-11dc-4521-91c6-c7b9c2e95cb5",
              assetId: "1099571987650",
              templateId: "638218",
              type: "pet",
              img: "https://atomichub-ipfs.com/ipfs/QmWC5SwG3KYdKauGpHCnxvLcYocp7XeFskPohU7U1s9PRn",
              attributes: {
                hp: 53,
                mana: 5,
                name: "Dragon",
                level: 1,
                strength: 5,
                dexterity: 6,
                obedience: 8,
                mana_regen: 2,
                intelligence: 5,
              },
            },
            {
              id: "abbb19a1-2d22-4510-b643-ca5f5017faa4",
              deckId: "1138a7e6-11dc-4521-91c6-c7b9c2e95cb5",
              assetId: "1099572752075",
              templateId: "640350",
              type: "cannon",
              img: "https://atomichub-ipfs.com/ipfs/QmXupmMfxWjMTe9cYEmG9ozQYQwkeTJxgmaMAVN3CwcEoV",
              attributes: {
                name: "Cannon 1",
                level: 1,
                strength: 4,
                mana_cost: 9,
                durability: 600,
              },
            },
            {
              id: "03767569-81ca-46b6-9948-805729448fd2",
              deckId: "1138a7e6-11dc-4521-91c6-c7b9c2e95cb5",
              assetId: "1099572750299",
              templateId: "640353",
              type: "boost",
              img: "https://atomichub-ipfs.com/ipfs/QmdVbK2kNzzdnDor1ApryJ7rCT4KZJTLCZ4N3qc4uAqZoU",
              attributes: {
                hp: 9,
                name: "Mystic Mind",
                level: 1,
                strength: 3,
                dexterity: 1,
                obedience: 1,
                durability: 100,
                intelligence: 4,
              },
            },
          ],
        },
        details: {
          pet: {
            totalHp: 62,
            totalStrength: 8,
            totalDexterity: 7,
            totalIntelligence: 9,
            totalObedience: 9,
            totalPoints: 95,
          },
          boost: {
            boostHp: 9,
            boostStrength: 3,
            boostDexterity: 1,
            boostIntelligence: 4,
            boostObedience: 1,
          },
          cannon: {
            mana_cost: 9,
            strength: 4,
            cannon_damage: 23.5,
          },
        },
      },
      playerTwo: {
        userID: "b02306f4-6e2e-4204-8d02-a2b0ecd84a8a",
        player: "leonardowaxr",
        deck: {
          id: "0a484c26-76b0-4b6f-95ed-a6659a84f972",
          playerId: "cf3eb931-5459-4639-a925-dbeb04ffcbd1",
          playerQueueId: "59cda347-04dc-4500-88d9-9ce4d4c16eee",
          cards: [
            {
              id: "53a4b76b-8356-4319-a25c-25303270b787",
              deckId: "0a484c26-76b0-4b6f-95ed-a6659a84f972",
              assetId: "1099572751268",
              templateId: "638220",
              type: "pet",
              img: "https://atomichub-ipfs.com/ipfs/QmdMNZnHbZ5nWZsDNTZNmG6Vvmjebkj63hb4rhGypar8jS",
              attributes: {
                hp: 46,
                mana: 5,
                name: "Eagle",
                level: 1,
                strength: 6,
                dexterity: 6,
                obedience: 4,
                mana_regen: 2,
                intelligence: 5,
              },
            },
            {
              id: "9d909db2-adf7-4f7d-8198-9190e6f73a7e",
              deckId: "0a484c26-76b0-4b6f-95ed-a6659a84f972",
              assetId: "1099572751262",
              templateId: "640350",
              type: "cannon",
              img: "https://atomichub-ipfs.com/ipfs/QmXupmMfxWjMTe9cYEmG9ozQYQwkeTJxgmaMAVN3CwcEoV",
              attributes: {
                name: "Cannon 1",
                level: 1,
                strength: 4,
                mana_cost: 9,
              },
            },
            {
              id: "03054ad8-c437-4c1f-aa67-7357bbcf22ed",
              deckId: "0a484c26-76b0-4b6f-95ed-a6659a84f972",
              assetId: "1099572751283",
              templateId: "640353",
              type: "boost",
              img: "https://atomichub-ipfs.com/ipfs/QmdVbK2kNzzdnDor1ApryJ7rCT4KZJTLCZ4N3qc4uAqZoU",
              attributes: {
                hp: 7,
                name: "Mystic Mind",
                level: 1,
                strength: 1,
                dexterity: 3,
                obedience: 1,
                durability: 100,
                intelligence: 5,
              },
            },
          ],
        },
        details: {
          pet: {
            totalHp: 53,
            totalStrength: 7,
            totalDexterity: 9,
            totalIntelligence: 10,
            totalObedience: 5,
            totalPoints: 84,
          },
          boost: {
            boostHp: 7,
            boostStrength: 1,
            boostDexterity: 3,
            boostIntelligence: 5,
            boostObedience: 1,
          },
          cannon: {
            mana_cost: 9,
            strength: 4,
            cannon_damage: 23.5,
          },
        },
      },
    },
    deletedPlayersFromQueue: {
      count: 2,
    },
  },
};
