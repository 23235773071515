import toast from "react-hot-toast";

import { cleanAndCapitalize } from ".";
import { IFormatedAsset } from "../@types";
import { maxLevel } from "./constants";

export const costToRepair = 50;
export const TrainPotionCost = 1;
export const costToActivate = 100;

export const levelUpPotionCost = (level: number): number => {
  return 2 ** (level - 1);
};

export const checkLevelUpReqs = (
  nftData: any,
  levelCap: any,
  potions: string | any[],
) => {
  const message: string[] = [];

  if (!nftData?.level) {
    message.push("This NFT needs to be activated for pvp");
  }

  if (nftData?.level === maxLevel) {
    message.push("This NFT reached the max level");
  }

  if (potions?.length < levelUpPotionCost(+nftData?.level)) {
    const potionName = potions?.[0]?.data?.name || "potions";
    message.push(
      `You don't have ${potionName} to proceed. Craft or buy more and try again.`,
    );
  }

  return message;
};

export const checkBalanceForActivation = (
  nft: { data: { level: any } },
  tokens: { balance: any }[],
) => {
  const message: string[] = [];

  if (nft?.data?.level) {
    message.push("All your Pets are already activated.");
  }

  if (parseFloat(tokens[0]?.balance || "0") < costToActivate) {
    message.push(
      `You do not have enough zombie tokens to activate this NFT, you need at least ${costToActivate} ZBC`,
    );
  }

  return message;
};

export const checkBalanceForRepair = (
  nft: IFormatedAsset,
  tokens: { balance: any }[],
) => {
  const message: string[] = [];

  if (!nft?.attributes?.durability) {
    message.push(
      "This NFT doesn't have durability on mutable data, this might be a error",
    );
  }

  if (+nft?.attributes?.durability === 600) {
    message.push("This NFT is already fully repaired");
  }

  if (parseFloat(tokens[0]?.balance || "0") < costToRepair) {
    message.push(
      `You do not have enough zombie tokens to repiar this NFT, you need at least ${costToRepair} ZBC`,
    );
  }

  return message;
};

export const checkToolBalanceForUpgrade = (
  nft: IFormatedAsset,
  tools: any[],
) => {
  const message: string[] = [];

  if (!tools) {
    message.push("You don't have any tools to upgrade this NFT");
  }

  if (Array.isArray(tools)) {
    message.push("You don't have any tools to upgrade this NFT");
  }

  if (!nft?.attributes?.level) {
    message.push("This NFT needs to be activated for pvp");
  }

  if (tools.length === 0) {
    message.push("You don't have any tools to upgrade this NFT");
  }

  return message;
};

export const checkBalanceForBulkRepair = (
  nfts: IFormatedAsset[],
  tokens: { balance: any }[],
  type: any,
) => {
  const message: string[] = [];

  if (nfts.length === 0) {
    message.push(`All your ${type} are fully repaired`);
  }

  if (parseFloat(tokens[0]?.balance || "0") < costToRepair) {
    message.push(
      `You need more ZBC to proceed, you need at least ${
        costToRepair * nfts.length
      } ZBC`,
    );
  }

  return message;
};

export const checkBalanceForBulkActivation = (
  nfts: string | any[],
  tokens: string | any[],
) => {
  const message: string[] = [];

  if (nfts.length === 0) {
    message.push("All your nfts are active for PVP in this filter options");
  }

  const messageNotHaveTokens =
    "You don't have any zombie tokens to do this action";

  if (!tokens) message.push(messageNotHaveTokens);

  if (!Array.isArray(tokens)) {
    message.push(messageNotHaveTokens);
  }

  if (tokens.length === 0) {
    message.push(messageNotHaveTokens);
  }

  if (parseFloat(tokens[0]?.balance || "0") < nfts.length * costToActivate) {
    message.push(
      "You do not have enough zombie tokens to activate this NFT, you need at least 100.0000 ZBC",
    );
  }

  return message;
};

export const checkBalanceForTrain = (
  nftData: any,
  potions: string | any[],
  levelCap: { max_hp: string | number; max_attr_value: string | number },
) => {
  const message: string[] = [];

  if (!nftData?.level) {
    message.push("This NFT needs to be activated for pvp");
  }

  if (potions?.length < TrainPotionCost) {
    const potionName = potions?.[0]?.data?.name || "potions";
    message.push(
      `You don't have ${potionName} to proceed. Craft or buy more and try again.`,
    );
  }

  const attrs: any[] = [];

  Object.entries(nftData).forEach(([key, value]: any[]) => {
    if (["mana", "mana_regen", "level", "name", "img"].includes(key)) {
      return;
    }

    if (key === "hp") {
      if (+value === +levelCap.max_hp) {
        return attrs.push({ key, valid: true });
      }
    }

    if (+value === +levelCap.max_attr_value) {
      return attrs.push({ key, valid: true });
    }
    return attrs.push({ key, valid: false });
  });

  if (!attrs.map(attr => attr.valid).some(item => item === false)) {
    message.push("You have reach the maximum for each attribute");
  }

  return message;
};

export const handleMessages = (failureMessages: string[]) => {
  failureMessages.forEach(message => toast(message));
};
