import { FaDiscord, FaMedium, FaTwitter } from "react-icons/fa";

export const Footer = () => {
  return (
    <section
      className={`absolute bottom-0 left-0 right-0 mt-8 flex h-[100px] w-screen flex-col justify-center gap-5 bg-[#000000a7] px-8 py-6 shadow-2xl md:h-[50px] lg:flex-row lg:gap-[150px] xl:gap-[350px] xl:px-10 2xl:gap-[500px] 2xl:px-24`}
    >
      <div className="flex flex-col items-center justify-center ">
        <span className={`text-center font-medium text-secondary`}>
          Designed & Engineered by{" "}
          <span
            className={`cursor-pointer underline`}
            onClick={() => open("https://apexnft.com.br/")}
          >
            Apex NFT Brasil
          </span>
        </span>
      </div>
      <div
        className={`right-[3%] flex items-end justify-center gap-5 md:absolute md:bottom-[10%]`}
      >
        <FaMedium
          size={30}
          className={`cursor-pointer hover:text-[#2400ff]`}
          onClick={() => open("https://medium.com/@zombiecoin")}
        />
        <FaDiscord
          size={30}
          className={`cursor-pointer hover:text-[#2400ff]`}
          onClick={() => open("https://discord.com/invite/yq44jGwggF")}
        />
        <FaTwitter
          size={30}
          className={`cursor-pointer hover:text-[#2400ff]`}
          onClick={() => open("https://twitter.com/ZombiecoinNFT")}
        />
      </div>
    </section>
  );
};
