import { useState } from "react";

import { FilterInputs } from "../components/UI/FilterInputs";
import { capitalize } from "../utils/capitalize";
import {
  boostCardSchema,
  boostsAttributes,
  cannonAttributes,
  petsCardSchema,
  petsAttributes,
  cannonsCardSchema,
} from "../utils/constants";
import { useFetch } from "./useFetch";

type IFilterOptions = {
  template: string;
  level: { min: number; max: number; inactives: boolean };
  attributes: { priority: string; order: string };
};

export const useFilter = (type: string, isSelection = false) => {
  const { zombieTemplates } = useFetch();

  const schemas = {
    Pets: petsCardSchema,
    Cannons: cannonsCardSchema,
    Amulets: boostCardSchema,
  };

  const templates: { name: string; template_id: string }[] = zombieTemplates
    ? zombieTemplates
        .filter(nft => nft.schema.schema_name === schemas[type])
        .reduce((accumulator, current) => {
          const existingItem = accumulator.find(
            item => item?.template_id === current?.template_id,
          );

          if (!existingItem) {
            if (
              current?.schema?.format?.some(
                (obj: { name: string; type: string }) =>
                  obj?.name === "strength",
              ) &&
              current?.immutable_data?.name
            )
              accumulator.push({
                template_id: current?.template_id,
                name:
                  `${current?.immutable_data?.name} - ${current?.template_id}` ||
                  `Template: ${current?.template_id}`,
              });
          }

          return accumulator;
        }, [])
    : [];

  const attributes =
    {
      Pets: petsAttributes,
      Cannons: cannonAttributes,
      Amulets: boostsAttributes,
    }?.[type] || null;
  const maxLevel =
    {
      Pets: 5,
      Cannons: null,
      Amulets: null,
    }?.[type] || null;

  const inactives = type !== "Pets" ? null : isSelection ? null : true;

  const filterOptions = {
    template: ["All", ...templates], // Dragon, Chicken, Turtle...
    attributes: { priority: attributes, order: ["ASC", "DESC"] }, // strength, dexterity, intelligence...
    level: { maxLevel, inactives }, // { max: 5, min: 1 }, // 1-1, 1-2, 1-3. 2-5, 3-4...
  };

  const [filterSelection, setFilterSelection] = useState<IFilterOptions>({
    template: "All", // Dragon, Chicken, Turtle...
    level: { min: 1, max: 5, inactives: true }, // { max: 6, min: 1 }, // 1-1, 1-2, 1-3. 2-5, 3-4...
    attributes: { priority: "All", order: "ASC" }, // ASC or DESC, // strength, dexterity, intelligence...
  });

  const applyFilter = nftArray => {
    if (nftArray?.length === 0) return nftArray;
    if (attributes?.length === 0) return nftArray;

    return nftArray
      .filter(
        nft =>
          (filterSelection?.template === "All" ||
            +nft.templateId === +filterSelection?.template) &&
          ((!nft?.data?.level && filterSelection?.level?.inactives) ||
            (+nft.data.level >= +filterSelection.level?.min &&
              +nft.data.level <= +filterSelection.level?.max)),
      )
      .sort((a, b) => {
        return filterSelection.attributes.order === "ASC"
          ? +b.data?.[filterSelection.attributes.priority] -
              +a.data?.[filterSelection.attributes.priority]
          : +a.data?.[filterSelection.attributes.priority] -
              +b.data?.[filterSelection.attributes.priority];
      });
  };

  const renderFilters = () => {
    if (attributes?.length === 0) return null;

    return (
      <div className="flex flex-wrap gap-12">
        {Object.entries(filterOptions).map((filterOpt, _) => {
          if (filterOpt[1] === null) return null;

          return (
            <div key={_}>
              <div className="flex flex-col">
                {capitalize(filterOpt[0])}

                <FilterInputs
                  filterOpt={filterOpt}
                  filterSelection={filterSelection}
                  setFilterSelection={setFilterSelection}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return { applyFilter, renderFilters };
};
