import { FaArrowUpWideShort } from "react-icons/fa6";

import { cleanAndCapitalize } from "../../../utils";
import { MultiSlideInput } from "./MultiSlideInput";

export const FilterInputs = ({
  filterOpt,
  filterSelection,
  setFilterSelection,
}) => {
  if (!filterOpt[1]) return null;

  if (filterOpt[0] === "level")
    return (
      <MultiSlideInput
        filter={filterSelection}
        setFilter={setFilterSelection}
        filterOpt={filterOpt}
      />
    );

  if (filterOpt[0] === "attributes") {
    return (
      <div className="flex gap-1">
        <select
          className={`rounded-lg bg-[#3B3B3B] p-2 outline-none`}
          value={filterSelection[filterOpt[0]]?.priority || 0}
          onChange={evt =>
            setFilterSelection(prevState => {
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.attributes.priority = evt.target.value;
              return newState;
            })
          }
        >
          {filterOpt[1].priority?.map((opts, number) => (
            <option key={number} value={opts}>
              {cleanAndCapitalize(opts)}
            </option>
          ))}
        </select>
        <div
          className={`cursor-pointer transition-all ${
            filterSelection.attributes.order === "ASC" ? "" : "rotate-180"
          } `}
          onClick={() =>
            setFilterSelection(prevState => {
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.attributes.order =
                prevState.attributes.order === "ASC" ? "DESC" : "ASC";
              return newState;
            })
          }
        >
          <span>
            <FaArrowUpWideShort width={64} height={64} />
          </span>
        </div>
      </div>
    );
  }

  return Array.isArray(filterOpt[1]) ? (
    <select
      value={filterSelection?.[filterOpt[0]]}
      className={`rounded-lg bg-[#3B3B3B] p-2 outline-none`}
      onChange={evt =>
        setFilterSelection(prevState => {
          const newState = JSON.parse(JSON.stringify(prevState));

          newState[filterOpt[0]] = evt.target.value;

          return newState;
        })
      }
    >
      {filterOpt[1].map((option, _) =>
        option.template_id ? (
          <option key={_} value={option.template_id}>
            {cleanAndCapitalize(option.name)}
          </option>
        ) : (
          <option key={_} value={option}>
            {cleanAndCapitalize(option)}
          </option>
        ),
      )}
    </select>
  ) : null;
};
