import toast from "react-hot-toast";

import { mutateApiData } from "../services/swr";
import { capitalize } from "../utils/capitalize";
import { atributeUpgradeStep, config, letterByLevel } from "../utils/constants";
import { costToActivate, costToRepair } from "../utils/errorHandlers";
import { rede } from "../utils/rede";
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { useEndpoint } from "./useEndPoint";

export interface IMutateOptions {
  key: any;
  currentData: any;
  fetcher: (url: string) => Promise<any>;
  // property: string;
}
const useWax = () => {
  const { api } = useApi();
  const { activeUserData } = useAuth();

  const { endpoint } = useEndpoint();

  if (activeUserData === null) {
    throw new Error("Cannot reach user data");
  }

  const auth = [
    {
      actor: activeUserData.actor.toString(),
      permission: activeUserData.permission.toString(),
    },
  ];

  const accountName = activeUserData.actor.toString();
  const contract =
    rede === "mainet"
      ? config.contractAccountMainet
      : config.contractAccountTestnet;

  const farmGameContract =
    rede === "mainet"
      ? config.farmGameContractMainet
      : config.farmGameContractTestnet;

  const updateAssets = async mutateOptions => {
    for (const option of mutateOptions) {
      await mutateApiData(
        option.key,
        option.currentData,
        option.fetcher,
        option.property,
      );
    }
  };

  const contractTransferTokenAction = (mutateOptions: IMutateOptions[]) => {
    const action = {
      account: "eosio.token",
      name: "transfer",
      authorization: auth,
      data: {
        from: accountName,
        to: contract,
        quantity: `1.0000 WAX`,
        memo: "deposit",
      },
    };
    const success = async () => {
      toast.success("Deposit success");

      for (const option of mutateOptions) {
        await mutateApiData(option.key, option.currentData, option.fetcher);
      }
    };

    const error = async () => {
      toast.error("Deposit failed");
    };

    activeUserData
      .transact({
        action,
      })
      .then(success)
      .catch(error);
  };

  const contractActivateForPVPAction = (asset_ids: string[], mutateOptions) => {
    const actions = [
      {
        account: config.contractTokenAccount!,
        name: "transfer",
        authorization: auth,
        data: {
          from: accountName,
          to: contract,
          quantity: `${asset_ids.length * costToActivate}.0000 ZBC`,
          memo: "deposit",
        },
      },
      {
        account: contract!,
        name: "setpvppets",
        authorization: auth,
        data: {
          asset_ids,
          owner: accountName,
        },
      },
    ];

    const success = async () => {
      toast.success("Activation success");
      updateAssets(mutateOptions);
    };

    const error = async () => {
      toast.error("Activation failed");
    };

    activeUserData
      .transact({
        actions,
      })
      .then(success)
      .catch(error);
  };

  const contractRepairNFT = (asset_ids: string[], mutateOptions) => {
    const actions = [
      {
        account: config.contractTokenAccount!,
        name: "transfer",
        authorization: auth,
        data: {
          from: accountName,
          to: contract,
          quantity: `${asset_ids.length * costToRepair}.0000 ZBC`,
          memo: "deposit",
        },
      },
      {
        account: contract!,
        name: "repair",
        authorization: auth,
        data: {
          asset_ids,
          player: accountName,
        },
      },
    ];
    const success = async () => {
      toast.success("NFT repaired successfully");
      updateAssets(mutateOptions);
    };

    const error = async () => {
      toast.error("NFT repair failed");
    };

    activeUserData
      .transact({
        actions,
      })
      .then(success)
      .catch(error);
  };

  const contractLevelUpCharacter = (
    { card_asset_id, fruit_asset_ids },
    mutateOptions,
  ) => {
    const actions = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: auth,
        data: {
          from: accountName,
          to: contract,
          asset_ids: fruit_asset_ids,
          memo: "deposit",
        },
      },
      {
        account: contract!,
        name: "upgradelvl",
        authorization: auth,
        data: {
          card_asset_id,
          owner: accountName,
        },
      },
    ];
    const success = async () => {
      toast.success("Level up");
      updateAssets(mutateOptions);
    };

    const error = async () => {
      toast.error("Level up failed");
    };

    activeUserData
      .transact({
        actions,
      })
      .then(success)
      .catch(error);
  };
  const contractLevelUpAtribute = ({
    card_asset_id,
    fruit_asset_ids,
    attribute,
    mutateOptions,
    onSuccess = null,
  }: {
    card_asset_id: string[];
    fruit_asset_ids: string[];
    attribute: string;
    mutateOptions: any;
    onSuccess?: null | VoidFunction;
  }) => {
    const actions = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: auth,
        data: {
          from: accountName!,
          to: contract,
          asset_ids: [fruit_asset_ids],
          memo: "deposit",
        },
      },
      {
        account: contract!,
        name: "upgradeatrr",
        authorization: auth,
        data: {
          card_asset_id,
          owner: accountName,
          attribute,
          value: atributeUpgradeStep[attribute],
        },
      },
    ];
    const success = async () => {
      toast.success("Attribute increased");
      if (onSuccess) onSuccess();

      updateAssets(mutateOptions);
    };

    const error = async () => {
      toast.error("Attribute increase failed");
    };

    activeUserData
      .transact({
        actions,
      })
      .then(success)
      .catch(error);
  };

  const contractLevelUpAcessory = (
    card_asset_id: string,
    tool_asset_id: string,
    type: string,
    level: number,
    mutateOptions: IMutateOptions[],
  ) => {
    if (!card_asset_id || !tool_asset_id) {
      toast.error("Invalid data");
      return;
    }

    if (!type) {
      toast.error("Invalid type");
      return;
    }

    if (!level) {
      toast.error("Invalid level");
      return;
    }

    if (level === 5) {
      toast.error("Max level reached");
      return;
    }

    const actions = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: auth,
        data: {
          from: accountName!,
          to: farmGameContract,
          asset_ids: [tool_asset_id, card_asset_id],
          memo: `${type}${letterByLevel[level]}`,
        },
      },
    ];
    const success = async () => {
      toast.success(
        `Level up your ${capitalize(type)} was succesfuly completed`,
      );

      updateAssets(mutateOptions);
    };

    const error = async () => {
      toast.error("Level up failed");
    };

    activeUserData
      .transact({
        actions,
      })
      .then(success)
      .catch(error);
  };

  return {
    contractTransferTokenAction,
    contractActivateForPVPAction,
    contractLevelUpCharacter,
    contractLevelUpAtribute,
    contractRepairNFT,
    contractLevelUpAcessory,
  };
};

export default useWax;
