import { reactRouterV3BrowserTracingIntegration } from "@sentry/nextjs";
import axios from "axios";
import Image from "next/image";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { FaCalendarAlt } from "react-icons/fa";
import { GiTrophy, GiTrophiesShelf, GiPunch } from "react-icons/gi";
import { GoTriangleRight } from "react-icons/go";

import { IQuest, IRewardsTable } from "../../../../@types/quests";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useFetch } from "../../../../hooks/useFetch";
import { apiURL, IFPS_URL } from "../../../../utils/constants";

const QuestIcons = {
  MATCHES_PLAYED: (props?: any) => <GiPunch {...props} />,
  WINS: (props?: any) => <GiTrophy {...props} />,
  CONSECUTIVE_DAYS: (props?: any) => <FaCalendarAlt {...props} />,
  WINS_IN_A_ROW: (props?: any) => <GiTrophiesShelf {...props} />,
};

const ConditionText = {
  MATCHES_PLAYED: "Play <x> matches to complete this quest",
  WINS: "Win <x> matches to complete this quest",
  CONSECUTIVE_DAYS: "Play <x> matches in a row to complete this quest",
  WINS_IN_A_ROW: "Win <x> matches in a row to complete this quest",
};

interface Props {
  quest: IQuest;
}

export const QuestDisplayItem = ({ quest }: Props) => {
  const isDailyQuest = quest.isDaily;
  // if is a daily quest, set the end of the quest to the end of the day else set it to the end of the month
  const endOfTheCurrentMonth = new Date();
  endOfTheCurrentMonth.setMonth(endOfTheCurrentMonth.getMonth() + 1); // Vai para o próximo mês
  endOfTheCurrentMonth.setDate(0); // Define para o último dia do mês anterior (final do mês atual)
  endOfTheCurrentMonth.setHours(23, 59, 59, 999); // Final do dia

  const timeToEnd = isDailyQuest
    ? new Date().setHours(23, 59, 59)
    : endOfTheCurrentMonth;
  const { zombieTemplates, allQuests } = useFetch();
  const { currentUser } = useCurrentUser();
  const [claimLoading, setClaimLoading] = useState(false);
  const [claimDisabled, setClaimDisabled] = useState(false);
  const myParticiaption = quest?.participations?.find(
    p => (p.progress?.[0] as any)?.wallet === currentUser,
  );
  const [allRewards, setAllRewards] = useState<IRewardsTable[] | null>(null);

  const canClaimReward = () => {
    if (!myParticiaption) return false;

    const allCompleted = (myParticiaption.progress as any)?.every(
      p => p.completed,
    );
    if (!allCompleted) return false;

    const lastClaimDate = new Date(myParticiaption.lastClaimDate || 0);
    const now = new Date();

    if (isDailyQuest) {
      lastClaimDate.setDate(lastClaimDate.getDate() + 1);
      return now >= lastClaimDate;
    } else {
      lastClaimDate.setMonth(lastClaimDate.getMonth() + 1);
      return now >= lastClaimDate;
    }
  };

  const checkAlreadyClaimed = () => {
    if (!myParticiaption?.lastClaimDate) return false;

    const lastClaimDate = new Date(myParticiaption.lastClaimDate);
    const now = new Date();

    if (quest.isDaily) {
      lastClaimDate.setDate(lastClaimDate.getDate() + 1);
      return now < lastClaimDate;
    } else {
      lastClaimDate.setMonth(lastClaimDate.getMonth() + 1);
      return now < lastClaimDate;
    }
  };

  const handleClaimQuest = async () => {
    setClaimLoading(true);
    try {
      await axios.post(`${apiURL}/quests/claim/${quest.id}/${currentUser}`);
      toast("Quest claimed successfully");
      setClaimDisabled(true);
    } catch (err) {
      console.log(err);
      toast("Error on claiming quest");
      setClaimDisabled(false);
    }
    setClaimLoading(false);
  };

  useEffect(() => {
    const handleReward = () => {
      const allRewardsMaps = new Map();

      quest?.rewards?.forEach(r => {
        if (!allQuests?.rewards) return;
        const findRewards =
          allQuests?.rewards?.filter(
            rew => rew?.taskname === r?.workinTaskId,
          ) || [];

        findRewards?.forEach(rewardsData => {
          const repeated = allRewardsMaps.get(
            `${rewardsData.odd}@${rewardsData.nftTemplate}`,
          );
          if (!repeated)
            allRewardsMaps.set(
              `${rewardsData.odd}@${rewardsData.nftTemplate}`,
              rewardsData,
            );
          else
            allRewardsMaps.set(
              `${rewardsData.odd}@${rewardsData.nftTemplate}`,
              {
                ...rewardsData,
                quantity:
                  Number(repeated.quantity) + Number(rewardsData.quantity),
              },
            );
        });
      });

      setAllRewards(Array.from(allRewardsMaps.values() || []));
    };

    handleReward();
  }, [quest, allQuests, quest?.rewards]);

  const IconComponent = QuestIcons[quest.conditions[0].conditionType];

  if (allRewards === null) {
    return (
      <div className="flex  animate-pulse items-center justify-center">
        <div className="mt-10 h-[320px] w-[250px] rounded-md  bg-basket_blue-950 md:w-[700px] md:p-6 xl:w-[1200px]"></div>
      </div>
    );
  }

  return (
    <div className="gold-border mt-10 flex w-[350px] flex-col items-center justify-center rounded-[15px] border-2 bg-basket_blue-950 p-2 text-left md:w-[700px] md:p-6 xl:w-[1200px]">
      <div>
        <IconComponent className="h-[52px] w-[52px]" />
      </div>
      <div className="font-bold uppercase">
        {quest.isDaily ? "Daily" : "Monthly"}:{" "}
        <span className="gold-text">{quest.name}</span>
      </div>
      <div>{quest.description}</div>
      <div>
        Quest resets in:{" "}
        <Countdown
          date={timeToEnd}
          renderer={({ days, hours, minutes, seconds }) => (
            <b>
              {days} day{days > 1 ? "s" : ""} {hours > 9 ? hours : `0${hours}`}h{" "}
              {minutes > 9 ? minutes : `0${minutes}`}m{" "}
              {seconds > 9 ? seconds : `0${seconds}`}s
            </b>
          )}
        />
      </div>
      {quest.participations.length > 1 ? (
        <div className="text-center font-bold">
          Conditions
          <div className="font-normal">
            {quest.conditions.map((c, _) => {
              return (
                <div key={_}>
                  {ConditionText[c.conditionType].replace(
                    "<x>",
                    String(c.value),
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <div className="group mt-4 flex flex-col items-center justify-center">
        <label className="flex flex-col items-center justify-center">
          <input
            type="checkbox"
            className="peer hidden font-bold transition-all"
          />
          <span className="flex cursor-pointer items-center justify-center font-bold transition-all duration-300 after:ml-2 after:text-[#e2ab49] after:content-['>'] peer-checked:after:rotate-90">
            Rewards
          </span>
          <div className="hidden gap-3 transition-all duration-500 group-focus:flex peer-checked:flex">
            <div className="flex flex-col gap-3">
              <div className="flex flex-wrap items-center justify-center gap-3">
                {allRewards &&
                  Array.isArray(allRewards) &&
                  allRewards?.map((rew2, _3) => {
                    const templateData = zombieTemplates.find(
                      zt => +zt.template_id === +rew2.nftTemplate,
                    );
                    return (
                      <div key={_3}>
                        <div className="relative flex items-center justify-center rounded-lg border-2 border-black bg-zombie_brown-900">
                          <div className="radial-blue z-1 absolute h-[70%] w-[70%] rounded-full bg-[rgb(122,182,239)]" />

                          {rew2.itemType === "NFT" ? (
                            <div className="z-2 tooltip relative min-h-[54px] min-w-[54px]">
                              <div className="button !-left-16">
                                {rew2.typeName}
                              </div>
                              <Image
                                width={54}
                                height={54}
                                src={`${IFPS_URL}${templateData?.immutable_data?.img}`}
                                alt="Reward Icon"
                                className="z-2 relative "
                              />
                            </div>
                          ) : (
                            <div className="z-2 relative flex h-[54px] w-[54px] items-center justify-center text-center font-bold text-black">
                              <p className="text-black">{rew2.typeName}</p>
                            </div>
                          )}
                          {/* {rew2.odd} */}
                          <div className="z-3 stroke-zombie_black gold-text absolute -right-3 -top-2 stroke-2">
                            {(Number(rew2.odd) / 100).toFixed(2)}%
                          </div>
                          <div className="z-3 stroke-zombie_black gold-text absolute -bottom-3 -left-2 whitespace-nowrap stroke-2 font-bold">
                            {rew2.itemType === "NFT"
                              ? `0 - ${Number(rew2.quantity)}`
                              : `0 - ${Number(rew2.quantity)}x`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </label>
      </div>
      <div className="group mt-4 flex flex-col items-center justify-center">
        <label className="flex flex-col items-center justify-center">
          <input
            type="checkbox"
            className="peer hidden font-bold transition-all "
          />
          <span className="after:!gold-text flex cursor-pointer  items-center justify-center font-bold transition-all duration-300 after:ml-2 after:text-[#e2ab49] after:content-['>'] peer-checked:after:rotate-90">
            Your Progress
          </span>
          <div className="hidden flex-col gap-3 transition-all duration-500 group-focus:flex peer-checked:flex">
            {myParticiaption && quest.participations.length > 0 ? (
              <div className="flex items-center justify-center gap-3 text-center">
                <div
                  className={
                    (myParticiaption.progress as any)?.[0]?.wallet ===
                      currentUser
                      ? "gold-text"
                      : ""
                  }
                >
                  {(myParticiaption.progress as any)?.[0]?.wallet || "Wallet"}
                </div>
                <div>
                  {checkAlreadyClaimed() || canClaimReward() ? (
                    <div className="text-green-500">Completed ✔</div>
                  ) : (
                    (myParticiaption.progress as unknown as any[])?.map(
                      (progressItem, _) => {
                        return (
                          <div key={_}>
                            <div className="flex items-center justify-center gap-3">
                              <progress
                                className={` progress-unfilled:rounded-full progress-filled:rounded-full progress-filled:bg-[#68eac0]
                                    dark:progress-unfilled:bg-white dark:progress-filled:bg-[#46CFDB]`}
                                value={progressItem.value}
                                max={progressItem.goal}
                              />
                              {progressItem.value}/{progressItem.goal}
                            </div>
                          </div>
                        );
                      },
                    )
                  )}
                </div>
              </div>
            ) : (
              "No Progress"
            )}
          </div>
        </label>
      </div>
      <div className="mt-4">
        <button
          className="gamebtn !min-w-[50px]"
          onClick={claimLoading ? undefined : () => handleClaimQuest()}
          disabled={claimDisabled || !canClaimReward()}
        >
          {claimLoading ? (
            <div className="loading-circle !h-[20px] !w-[20px] after:!hidden"></div>
          ) : checkAlreadyClaimed() ? (
            "Completed"
          ) : (
            "Claim"
          )}
        </button>
      </div>
    </div>
  );
};

interface BattleHistoryItemProps {
  history: {
    id: string;
    date: string;
    winnerId: string;
    createdAt: string;
    updatedAt: string;
    isDraw: boolean;
    isRanked: boolean;
    players: {
      player: {
        id: string;
        player: string;
        userID: string;
        createdAt: string;
        updatedAt: string;
        isActive: boolean;
        rank: string;
      };
    }[];
    rounds: {
      round: number;
      isActive: boolean;
      turns: {
        turn: number;
        isActive: boolean;
      }[];
    }[];
    lastRound: number;
    lastTurn: number;
    win: boolean;
    draw: boolean;
  };
}

export const HistoryDisplayItem = ({ history }: BattleHistoryItemProps) => {
  return (
    <section className="gold-border mt-10 flex w-[350px] flex-col items-center justify-center rounded-[15px] border-2 bg-basket_blue-950 p-2 text-left md:w-[700px] md:p-6 xl:w-[1200px]">
      <div className="flex flex-col items-center justify-center">
        <div>
          {history.win ? (
            <div className="font-bold text-green-500">You Win</div>
          ) : history.draw ? (
            <div className="font-bold text-yellow-500">Draw</div>
          ) : (
            <div className="font-bold text-red-500">You Lose</div>
          )}
        </div>
        <div className="mt-2 text-center">
          <div className="font-bold">Date:</div>
          <div>{new Date(history.date).toLocaleString()}</div>
        </div>
      </div>
      <div className="mt-2 text-center">
        <div className="font-bold">Players:</div>
        <ul className="flex gap-2 text-center">
          {history.players.map((p, index) => (
            <li key={index}>
              {p.player.player}{index === 0 && " Vs."}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex gap-4 text-center">
        <div className="mt-2">
          <div className="font-bold">Last Round:</div>
          <div>{history.lastRound}</div>
        </div>
        <div className="mt-2">
          <div className="font-bold">Last Turn:</div>
          <div>{history.lastTurn}</div>
        </div>
        <div className="mt-2">
          <div className="font-bold">Ranked:</div>
          <div>{history.isRanked ? "Yes" : "No"}</div>
        </div>
      </div>
    </section>
  );
};
