import { motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import { FaHandPointDown } from "react-icons/fa";

import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useFetch } from "../../../hooks/useFetch";
import { HistoryDisplayItem } from "../../UI/Items/HistoryDisplayItem";

export const History = () => {
  const { userHistoryBattle, isLoadingUserHistoryBattle } = useFetch();
  // const { currentUser } = useCurrentUser();
  // const [itemsMoreThenViewPort, setItemsMoreThenViewPort] = useState(false);

  // const questsRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const checkHeight = () => {
  //     if (questsRef.current) {
  //       setItemsMoreThenViewPort(
  //         questsRef.current.scrollHeight > window.innerHeight,
  //       );
  //     }
  //   };

  //   checkHeight();
  //   window.addEventListener("resize", checkHeight);

  //   return () => {
  //     window.removeEventListener("resize", checkHeight);
  //   };
  // }, []);

  if (isLoadingUserHistoryBattle || !userHistoryBattle) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <div className="loading-circle">
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <section className="mb-10 mt-10 flex w-full flex-col items-center justify-center md:w-[100%] md:min-w-[405px] ">
      <article className={`flex flex-col items-center gap-2`}>
        <h4 className="font-impact relative w-full text-center text-4xl font-normal uppercase text-white">
          History{" "}
          {/* {itemsMoreThenViewPort && (
            <span className="absolute -right-20 flex animate-bounce flex-col items-center justify-center">
              <span className="whitespace-nowrap text-xs">Scroll Down</span>
              <FaHandPointDown />
            </span>
          )} */}
        </h4>
      </article>

      <section
        // ref={questsRef}
        className="flex flex-col items-center justify-center"
      >
        {userHistoryBattle && Array.isArray(userHistoryBattle) ? (
          userHistoryBattle.map((e: any, _) => {
            return (
              <motion.div
                key={_}
                whileInView={{ y: [100, 0], opacity: [0, 1] }}
                initial={{ y: 100, opacity: 0 }}
              >
                <HistoryDisplayItem key={_} history={e} />
              </motion.div>
            );
          })
        ) : (
          <div className="flex h-[200px] items-center justify-center font-bold">
            Couldn&apos;t find any previous battle now
          </div>
        )}
      </section>
    </section>
  );
};
