import Image from "next/image";
import { ReactNode } from "react";

import { useAuth } from "../../../../hooks/useAuth";

export const NavItem = ({ item, handleOnClickNav }) => {
  const { activeUserData } = useAuth();

  let displaying: string | ReactNode = item;
  if (item === "Login") {
    displaying = (
      <div
        className={
          "gamebtn !h-full !w-full text-center" +
          " text[calc(.8rem_+_.3vw)] relative flex cursor-pointer items-center justify-center rounded-[10px] text-center font-[400] leading-[1.5] text-[rgba(255,255,255,.9)] shadow-sm transition-all hover:bg-[rgba(150,150,150,.9)] " +
          `${
            activeUserData && item === "Login"
              ? "after:absolute after:inset-0 after:m-auto after:flex after:items-center  after:justify-center after:text-[rgba(255,255,255,.9)] hover:text-transparent hover:after:content-['Logout']"
              : ""
          }`
        }
      >
        {activeUserData?.actor.toString() || "Login"}
      </div>
    );
  }

  if (["Sound", "Twitter", "Discord"].includes(item)) {
    displaying = (
      <Image
        width={34}
        height={34}
        src={`/image/icons/icon_${item.toLowerCase()}1.png`}
        alt={item}
      />
    ) as ReactNode;
  }

  return (
    <ol
      onClick={() => handleOnClickNav(item)}
      className={
        item === "Login"
          ? "w-[160px]"
          : `${
              ["Sound", "Twitter", "Discord"].includes(item)
                ? " w-[40px] "
                : "min-w-[7vw] px-4"
            } ` +
            " text[calc(.8rem_+_.3vw)] relative flex cursor-pointer items-center justify-center  rounded-[10px] text-center font-[400] font-bold leading-[1.5] text-[rgba(255,255,255,.9)] shadow-sm transition-all hover:bg-[rgba(150,150,150,.9)] "
      }
    >
      {displaying}
    </ol>
  );
};
