import { rede } from "./rede";

export const accountToken = "eosio.token";
export const waxAccountToken = "eosio.token";

export const IFPS_URL = "https://atomichub-ipfs.com/ipfs/";
const isMainet = rede === "mainet";

export const config = {
  contractTokenAccountTestnet: process.env
    .contractTokenAccountTestnet as string,
  contractAccountMainet: process.env.contractAccountMainet as string,
  contractAccountTestnet: process.env.contractAccountTestnet as string,
  contractTokenAccount: isMainet
    ? process.env.contractTokenAccountMainet
    : process.env.contractTokenAccountTestnet,
  farmGameContractMainet: process.env.farmGameContractMainet as string,
  farmGameContractTestnet: process.env.farmGameContractTestnet as string,
  collectionNameMainet: process.env.collectionNameMainet as string,
  collectionNameTestnet: process.env.collectionNameTestnet as string,
  atomicTestnet: process.env.atomicTestnet as string,
  atomicMainet: process.env.atomicMainet as string,
  bloksTestnet: process.env.bloksTestnet as string,
  bloksMainet: process.env.bloksMainet as string,
  ga_tracking_id: process.env.ga_tracking as string,
  chainIdMainet: process.env.chainIdMainet as string,
  chainIdTestnet: process.env.chainIdTestnet as string,
  appNameTest: process.env.appNameTest as string,
  appNameMainet: process.env.appNameMainet as string,
  host: process.env.host as string,
  apiUrl:
    (process.env.apiURL as string) ??
    "https://socket-zombie-3d5e295f1e17.herokuapp.com",
};

export const publicRoutes = ["/"];

export const tablesApi = {
  // Old Routes
  // templates: `${process.env.draftEndpoint}/api/v1/rows/blendcfg`,
};

export const joinedLogMessages = {
  nok: ["Your attack has been blocked!. Your opponent has a counter attack!"],
  ok: ["Your opponent's attack has been blocked!. You have a counter attack!"],
  even: ["No attack was cast. Both players have tried defending!"],
};

export const atributeUpgradeStep = {
  hp: 5,
  mana: 1,
  strength: 1,
  dexterity: 1,
  intelligence: 1,
  obedience: 1,
};

export const collectionRoutes = {};

export const statusRoutes = {};

export const petsCardSchema = isMainet ? "pets" : "pets2";
export const boostCardSchema = isMainet ? "amulet" : "amulets";
export const cannonsCardSchema = isMainet ? "cannon" : "cannons";

const typeBySchemaMainet = {
  pets: "pet",
  amulet: "amulet",
  cannon: "cannon",
};
const typeBySchemaTestnet = {
  pets2: "pet",
  amulets: "amulet",
  cannons: "cannon",
};

export const typeBySchema = isMainet ? typeBySchemaMainet : typeBySchemaTestnet;

export const petsLabel = "Pets Cards";
export const cannonsLabel = "Cannons Cards";
export const boostsLabel = "Amulets Cards";
export const apiURL = config.apiUrl;

export const potionsSchema = "potions";
export const trainPotionTemplateId = isMainet ? "792531" : "640540";
export const levelUpPotionTemplateId = isMainet ? "792530" : "640541";
export const levelUpToolTemplateId = isMainet ? "" : "640542";

type TemplateItem = {
  template_id: string;
  name: string;
};

const petTemplatesMainet: TemplateItem[] = [
  {
    template_id: "621176",
    name: "chicken",
  },
  {
    template_id: "621180",
    name: "dragon",
  },
  {
    template_id: "621177",
    name: "turtle",
  },
  {
    template_id: "621178",
    name: "eagle",
  },
  {
    template_id: "621179",
    name: "alligator",
  },
  {
    template_id: "836031",
    name: "wuffi",
  },
];

const petTemplatesTestnet: TemplateItem[] = [
  {
    template_id: "638221",
    name: "chicken",
  },
  {
    template_id: "638218",
    name: "dragon",
  },
  {
    template_id: "638219",
    name: "turtle",
  },
  {
    template_id: "638220",
    name: "eagle",
  },
  {
    template_id: "638222",
    name: "alligator",
  },

  {
    template_id: "836031",
    name: "wuffi",
  },
];

export const petTemplates = isMainet ? petTemplatesMainet : petTemplatesTestnet;

const cannonTemplatesMainet: string[] = [
  "791918",
  "791919",
  "791920",
  "791921",
  "791922",
];

const cannonTemplatesTestnet: string[] = ["640350"];

export const cannonTemplates: string[] = isMainet
  ? cannonTemplatesMainet
  : cannonTemplatesTestnet;

export const petsAttributes = [
  "All",
  "level",
  "strength",
  "dexterity",
  "intelligence",
  "obedience",
  "mana",
  "mana_regen",
  "hp",
];

export const objOfGrayBarMin_Cost = {
  "1": "0%",
  "2": "20%",
  "3": "35%",
  "4": "45%",
};
export const objOfGrayBarMax_Cost = {
  "5": "0%",
  "4": "20%",
  "3": "40%",
  "2": "54%",
};

export const cannonAttributes = ["All", "level", "mana_cost"];
export const boostsAttributes = [
  "All",
  "hp",
  "strength",
  "dexterity",
  "intelligence",
  "obedience",
  "durability",
];

export const AttributePotionName = "Valor Infusion Potion";
export const LevelUpPotionName = "Pinnacle Progress Potion";
export const maxLevel = 5;
export const letterByLevel = {
  1: "b",
  2: "c",
  3: "d",
  4: "e",
};
