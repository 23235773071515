import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { ISelectedCards } from "../@types/game";
import {
  IFormatedSocketData,
  ISocketMatchFound,
  ISocketQueueDetails,
} from "../@types/socket";
import socket from "../services/socket";
import { useAuth } from "./useAuth";
import { useBattle } from "./useBattle";
import { useGame } from "./useGame";

export const useSocket = () => {
  const { activeUserData } = useAuth();
  const {
    setStage,
    setRoundData,
    selectedCards,
    stage,
    setRawSelectedCards,
    setQueueDetails,
  } = useGame();
  const { setInitialRoundData } = useBattle();

  let turnTimeout;

  function getUpdate() {
    console.log("Solicitando atualização do estado do jogo...");
    socket.emit("update-battle", activeUserData?.actor.toString());
  }

  function startTimeout() {
    if (stage === "battle") {
      clearTimeout(turnTimeout); // Limpar o timeout anterior
      turnTimeout = setTimeout(() => {
        getUpdate(); // Solicitar atualização do servidor
      }, 10000); // 10 segundos de timeout
    }
  }

  const currentDate = new Date();

  const [globalTime, setGlobalTime] = useState(currentDate.getTime() || 0);

  useEffect(() => {
    const timer = () =>
      setTimeout(() => {
        getNowSocketTime();
      }, 500);
    timer();
  }, [globalTime]);

  useEffect(() => {
    const sessionID = activeUserData?.actor.toString() as string;

    if (sessionID) {
      socket.auth = { sessionID };
      socket.connect();
    } else {
      socket.connect();
    }

    socket.on("session", ({ sessionID, userID }: any) => {
      socket.auth = { sessionID };
      localStorage.setItem("sessionID", sessionID);
      (socket as any).userID = userID;
    });

    // enter in the queue socket.emit("joinQueue");

    socket.on("joined-to-matchmaking", (data: IFormatedSocketData) => {
      console.log(data);
      if (!data) {
        toast("Something went wrong. Wait a minute and try again");
        return;
      }
      setStage("queue");

      const obj: ISelectedCards = { pet: null, cannon: null, boost: null };
      data.player.deck.cards.forEach(card => {
        obj[card.type] = card;
      });

      setRawSelectedCards(obj);
      // setSelectedCard(data);
    });

    socket.on("match-found", (data: ISocketMatchFound) => {
      clearTimeout(turnTimeout); // Turno recebido, limpar o timeout
      console.log("match-found");
      setRoundData(data);
      setStage("battle");
      startTimeout(); // Reiniciar timeout
    });

    socket.on("battleCurrentState", data => {
      clearTimeout(turnTimeout); // Turno recebido, limpar o timeout
      if (data && stage === "battle") {
        setRoundData({ data });
      }

      startTimeout(); // Reiniciar timeout
    });

    socket.on("abandon-matchmaking", () => {
      setStage("selection");
    });

    socket.on("game-abandoned", game => {
      setRoundData({
        data: {
          game,
          game_abandoned: true,
        },
      });
      setInitialRoundData({
        data: {
          game,
        },
      });
      setStage("reward-lose");
    });

    socket.on("game-won", game => {
      setRoundData({ data: game });
      setStage("reward-win");
    });

    socket.on("game-won-abandon", game => {
      setRoundData({
        data: {
          game,
          game_won_abandon: true,
        },
      });
      setStage("reward-win");
    });

    socket.on("game-won-surrender", data => {
      setStage("reward-win");
    });

    socket.on("game-lost", game => {
      setRoundData({ data: game });
      setStage("reward-lose");
    });

    socket.on("players-in-queue", (data: ISocketQueueDetails[]) => {
      setQueueDetails(data);
    });

    socket.on("game-draw", game => {
      setRoundData({ data: game });
      setStage("reward-draw");
    });

    socket.on("game-away", game => {
      setRoundData({
        data: {
          game,
          game_away: true,
        },
      });
      setStage("reward-win");
    });

    socket.on("receive-time", time => {
      setGlobalTime(time);
    });

    return () => {
      socket.off("joined-to-matchmaking");
      socket.off("match-found");
      socket.off("abandon-matchmaking");
      socket.off("game-abandoned");
      socket.off("game-won");
      socket.off("players-in-queue");
      socket.off("game-draw");
      socket.off("game-away");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserData?.actor.toString()]);

  function handleEndTurn(data: { gameId: string; turnId: string }) {
    if (stage !== "battle") {
      return;
    }
    socket.emit("end-turn", data);
  }

  function getNowSocketTime() {
    socket.emit("get-time");
  }

  function getCurrentDate() {
    const date = new Date(globalTime);
    return date;
  }

  function handleSocketBattleAction(type: string) {
    if (stage !== "battle") {
      return;
    }
    socket.emit("battle-action", {
      wallet: activeUserData?.actor.toString(),
      type,
    });
  }

  function handleEnterQueue() {
    if (selectedCards?.pet) {
      socket.emit("join-matchmaking", selectedCards);
      // setStage("queue");
    } else {
      toast("You need to select at least 1 Pet");
    }
  }

  function handleQuitQueue() {
    if (stage !== "queue") {
      return;
    }
    socket.emit("quit-from-matchmaking");
    setStage("selection");
  }

  function handleSurrenderBattleAction() {
    socket.emit("surrender");
    setStage("selection");
  }

  return {
    handleEnterQueue,
    handleQuitQueue,
    handleSurrenderBattleAction,
    handleSocketBattleAction,
    handleEndTurn,
    getCurrentDate,
  };
};
