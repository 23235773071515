import React from "react";

import Loader from "../Loader";

interface PlayerData {
  player: string;
  points: number;
  wonGames: number;
  lossGames: number;
  drawGames: number;
  totalGames: number;
}

interface PlayersRankingProps {

  ranking: PlayerData[] | null;
}



const tableStructureStyle =
  "!w-[550px] min-w-[100%] md:!w-[950px] md:justify-around md:gap-0 justify-start gap-8 flex justify-start border-b-2 md:w-auto";

const tableCellWidth = {
  lg: "min-w-[100px] max-w-[100px]",
  md: "min-w-[78px] max-w-[78px]",
  sm: "min-w-[50px] max-w-[50px]",
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const PlayersRanking = ({
  ranking,
}: PlayersRankingProps) => {
  return (
    <div className="overflow-x-auto overflow-y-hidden">
      <div className={`my-5 flex ${tableStructureStyle}`}>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Position
        </span>
        <span
          className={`font-impact ${tableCellWidth.lg} font-medium uppercase text-white md:text-xl`}
        >
          Player
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Points
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Wins
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Losses
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Draw
        </span>
        <span
          className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
        >
          Total Matches
        </span>
      </div>
      {!ranking && <Loader />}
      {ranking && ranking.length > 0 ? (
        ranking.map((data, index) => (
          <div key={index} className={`mb-5 mt-5 flex ${tableStructureStyle}`}>
            <span
              className={`font-impact ${tableCellWidth.sm
                } font-medium uppercase md:text-lg ${index < 20 ? "text-yellow-300" : "text-white"
                }`}
            >
              #{index + 1}
            </span>
            <span
              className={`font-impact ${tableCellWidth.lg} cursor-copy font-medium lowercase text-white md:text-lg`}
              onClick={() => copyToClipboard(data.player)}
            >
              {data.player}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
            >
              {data.points}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
            >
              {data.wonGames}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
            >
              {data.lossGames || 0}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
            >
              {data.drawGames}
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
            >
              {data.totalGames}
            </span>
          </div>
        ))
      ) : (
        <div className="flex h-[300px] items-center justify-center">
          <span className="text-2xl text-[#eee]">No results found.</span>
        </div>
      )}
    </div>
  );
};

export default PlayersRanking;
