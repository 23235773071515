import { all } from "axios";

import { IFormatedAsset } from "../../../../@types";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import { useFetch } from "../../../../hooks/useFetch";
import useWax, { IMutateOptions } from "../../../../hooks/useWax";
import { SWRCacheKeyGetters, atomicFetcher } from "../../../../services/swr";
import {
  checkBalanceForBulkActivation,
  costToActivate,
  handleMessages,
} from "../../../../utils/errorHandlers";

export const PetsBulkActionsButtons = ({ allInactive }) => {
  const { zombieTokenBalance, userInventory } = useFetch();

  const { currentUser } = useCurrentUser();
  const { contractActivateForPVPAction } = useWax();

  const bulkActivationBoudaryMessages = checkBalanceForBulkActivation(
    allInactive,
    zombieTokenBalance || [],
  );

  const mutateOptions: IMutateOptions[] = [
    {
      key: SWRCacheKeyGetters.getUserInventory(currentUser),
      currentData: userInventory,
      fetcher: atomicFetcher,
    },
  ];

  const renderText = () => {
    if (allInactive.length <= 20) {
      if (allInactive.length <= 0) {
        return "No Inactive Pets";
      }
      return `Activate Pets ${allInactive.length}`;
    }
    return `Activate Pets ${allInactive.slice(0, 20).length} / ${
      allInactive.length
    }`;
  };

  return (
    <div className="flex items-end justify-center">
      <div className="tooltip">
        <span className="button !left-[-22px] w-[200px]">
          Pets acquired prior to PVP launch must be activated. It costs{" "}
          {costToActivate.toLocaleString("en-us", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 2,
          })}{" "}
          ZBC per pet.
          {allInactive.length > 20 && (
            <>
              {" "}
              <br />
              Up to 20 Amulets can be activated at once
            </>
          )}
        </span>
        <button
          className={
            (bulkActivationBoudaryMessages.length > 0 ? "grayscale " : "") +
            "gamebtn "
          }
          onClick={
            bulkActivationBoudaryMessages.length > 0
              ? () => handleMessages(bulkActivationBoudaryMessages)
              : () =>
                  contractActivateForPVPAction(
                    allInactive.map((nft: IFormatedAsset) => nft.assetId),
                    mutateOptions,
                  )
          }
        >
          {renderText()}
        </button>
      </div>
    </div>
  );
};
